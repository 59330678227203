import React, { useCallback } from 'react';
import {
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useDialog } from '@a1-ict/core/dialogSlice/useDialog';
import { bgNumberRegex } from '../../data/validationsRegex';
import { settings } from '../../settings';

import { sendContact } from '@a1-ict/fsbo/generalSlice';

import { selectIsLoggedIn } from '@a1-ict/core/authSlice';

const ContactForm = () => {
  const { toggle, close, isOpen } = useDialog('contactForm');
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch();
  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    const { name, email, phone, description } = e.target;
    if (!phone.value.match(bgNumberRegex)) {
      toast.error('Моля въведете валиден телефонен номер', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      return;
    }

    handleRequest({
      name: name.value,
      email: email.value,
      phone: phone.value,
      description: description.value,
      terms_consent: terms_consent.checked,
    });
  }, []);

  const handleRequest = async (data) => {
    const result = await dispatch(sendContact({ dispatch, settings, body: data }));
    if (result?.payload?.ok) {
      toast.success('Успешно изпратено запитване!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
      close();
    } else
      toast.error(result?.error?.message || 'Възникна грешка! Моля опитайте по-късно!', {
        position: toast.POSITION.BOTTOM_CENTER,
      });
  };

  return (
    <Modal isOpen={isOpen} scrollable={true} toggle={toggle}>
      <ModalHeader toggle={toggle}>Свържете се с нас</ModalHeader>
      {isLoggedIn ? (
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Input type="text" name="name" id="name" placeholder="Вашето име" required="" />
            </FormGroup>
            <FormGroup>
              <Input
                type="email"
                name="email"
                id="email"
                placeholder="Вашият e-mail"
                required=""
                title="Пример: office@fsbo.bg"
              />
            </FormGroup>
            <FormGroup>
              <Input type="text" name="phone" id="phone" placeholder="Вашият телефон" required="" />
            </FormGroup>
            <FormGroup>
              <Input
                type="textarea"
                name="description"
                id="description"
                placeholder="Въведете съобщение"
                required=""
              />
            </FormGroup>
            <div className="form_wrapper">
              <input type="checkbox" id="terms_consent" name="terms_consent" required="" />
              <label htmlFor="consent-box">
                Съгласен съм с
                <a href="/terms/common" target="_blank" className="font-weight-bold animated mx-2">
                  Условията за ползване
                </a>
                и
                <a href="/terms/privacy" target="_blank" className="font-weight-bold animated mx-2">
                  Политиката за поверителност.
                </a>
              </label>
            </div>
          </ModalBody>
          <ModalFooter>
            <button className="btn animated">
              <span>Изпрати</span>
            </button>
            <Button color="secondary" onClick={close}>
              Затвори
            </Button>
          </ModalFooter>
        </Form>
      ) : (
        <div className="p-5">Моля влезте в профила си, за да може да изпратите запитване.</div>
      )}
    </Modal>
  );
};

export default ContactForm;
